import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Alarm/Push_Service/SuccessBox';
import PushTable from 'components/Web_User_Interface/1080p_Series/Alarm/Push_Service/pushTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Push Service",
  "path": "/Web_User_Interface/1080p_Series/Alarm/Push_Service/",
  "dateChanged": "2020-03-12",
  "author": "Mike Polinowski",
  "excerpt": "Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for IOS or ANDROID devices.",
  "image": "./WebUI_1080p_SearchThumb_Alarm_Push_Service.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Alarm_Push_Service.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Alarm Menu // Push Service' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your camera can notify you by Email every time a motion detection is triggered. A push notification serves the same purpose but is a lot faster! Activate Push if you want to be notified right away when something is happening in front of your camera. The push notification only works with our InstarVision app for IOS or ANDROID devices.' image='/images/Search/WebUI_1080p_SearchThumb_Alarm_Push_Service.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Alarm_Push_Service.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Alarm/Push_Dienst/' locationFR='/fr/Web_User_Interface/1080p_Series/Alarm/Push_Service/' crumbLabel="Push Service" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "alarm-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarm-menu",
        "aria-label": "alarm menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarm Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a1fc7dd015827aac7cb2645a37992abe/bb27a/1080p_Settings_Alarm_Push.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACzUlEQVQoz22RXUhTYRjHT0GEYTfaBymiQviRH2kKfQnTbUlYzo/K6Mu06aaos23nvB9n53Ted3OVxkioLiwRColK68KrzKhu1GY3EYhlQjiRIPRmwzad+sbZjKD68dy9/J7/8zwvhwAPIUAIIYwlEUO3p6VvpK1vxPxguPH+Rpl6Xpr73ti6eiSMZUW5YjTm5OblHSjgoCDwAgQAUEqrKisqTp+Z9a/NLIamF4LTiyG1FkJfFn7Or7Dng4Owtlaora8/ear84NGijCwOAmjlIQCww+XS6UtLSkoZYyy8vL62yn6zFl5hjL0fetx71dxldoiGS+JxTdX+Ak7E0A4ghJBSp6H8RFtT3Tpja6urfr9/cnLSOzExNfU5vKLKQ/0D7WUXPU3mblO7QVufn3OIExG0CRBASJ0uQ1mp6bwhmjY2Nma1Ws1ms6IoS0tLjLGHd+8NWqs/OXUfb194SqR2fSGHIbAJUACwo0MdW1Osjcqjo6MWi8VkMnk8nmAwyBgbfvHIUlV4rjDrVmXK26ak143qzsAubOys1+u1Wl1U9nq9lFKMsdvtDvj9jLH+gSeJyWkZe7N370zclxiHD8dzQBCsPAAQulT5WIl2IzkQCMzNzfl8sz7fbGg5xBi70929J35HelpGfFxc7LYYfXKMmixABGHk2jqdRlPM5sfZt1frq2HG2MyPZe/X4Pd348z74XrnzfiEXSlpqbHbY7Zu2VyUsEk9GMIYIUQIOVtTk56Z3cnX9ZLLdQ1NfFvrs2bLDVNLq7GBt7eZcLPBWH2kQpOTn5uRk5mUmsI5EMQREEKyLDskCQg8BILVZuNttms8L/B2K8/bBUGWJbe7g1BCCFEUhRDlb9mp4nI6XVGo6w+U0ohDKKGUUkIoJ2EoilgUVV+SZbWh2jryTiMp/6BEIIRw6lwARr4aORwOhEWERdEhqcjyf01ZliVJQgj9Ar3Au62/peGcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1fc7dd015827aac7cb2645a37992abe/e4706/1080p_Settings_Alarm_Push.avif 230w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/d1af7/1080p_Settings_Alarm_Push.avif 460w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/7f308/1080p_Settings_Alarm_Push.avif 920w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/b0250/1080p_Settings_Alarm_Push.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a1fc7dd015827aac7cb2645a37992abe/a0b58/1080p_Settings_Alarm_Push.webp 230w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/bc10c/1080p_Settings_Alarm_Push.webp 460w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/966d8/1080p_Settings_Alarm_Push.webp 920w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/f16bf/1080p_Settings_Alarm_Push.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a1fc7dd015827aac7cb2645a37992abe/81c8e/1080p_Settings_Alarm_Push.png 230w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/08a84/1080p_Settings_Alarm_Push.png 460w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/c0255/1080p_Settings_Alarm_Push.png 920w", "/en/static/a1fc7dd015827aac7cb2645a37992abe/bb27a/1080p_Settings_Alarm_Push.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a1fc7dd015827aac7cb2645a37992abe/c0255/1080p_Settings_Alarm_Push.png",
              "alt": "Web User Interface - 1080p Series - Alarm Push Service",
              "title": "Web User Interface - 1080p Series - Alarm Push Service",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PushTable mdxType="PushTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      